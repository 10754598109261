import { ElementRef, Injectable } from '@angular/core';
import { AsyncValidator, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { TimePeriod } from 'src/app/@core/model/time-period';

@Injectable({
  providedIn: 'root',
})
export class DatePickerFormatAsyncValidator implements AsyncValidator {
  private format: string;
  private datePickerInput: ElementRef;

  constructor(datePickerInput: ElementRef, format: string) {
    this.datePickerInput = datePickerInput;
    this.format = format;
  }

  validate = (control: UntypedFormControl): Observable<any> => {
    return of(this.checkFormat(control));
  };

  checkFormat(control: UntypedFormControl): ValidationErrors | null {
    const isValid = control.value ? (control.value as moment.Moment).isValid() : false;
    const isCorrectFormatted = control.value?.format(this.format) === this.datePickerInput?.nativeElement.value;

    return isValid && isCorrectFormatted ? null : { pattern: true };
  }
}
export function datepickerInvalidFormatValidator(datePickerInput: ElementRef, format: string): ValidatorFn {
  return (control: UntypedFormControl): ValidationErrors | null => {
    const isValid = control.value ? (control.value as moment.Moment).isValid() : false;
    const isCorrectFormatted = control.value?.format(format) === datePickerInput?.nativeElement.value;

    return isValid && isCorrectFormatted ? null : { pattern: true };
  };
}

export function invalidTimePeriodValidator(timePeriod: TimePeriod): ValidatorFn {
  return (): ValidationErrors | null => {
    return timePeriod?.startTime?.isAfter(timePeriod.endTime) ? { invalidRange: true } : null;
  };
}
